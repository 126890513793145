import {
  GET_COGS_FROM_ALGOLIA,
  RESET_COGS,
  SET_SEARCH_VALUE,
  RESET_SEARCH_VALUE,
  DELETE_COG,
} from '../constants/redux';
import { getCogs } from '../algolia/cogs.api';

export const getCogsFromAlgolia = (offset, searchText, length) => async dispatch => {
  try {
    const result = await getCogs({ offset, searchText, length });
    if (result) {
      dispatch({
        type: GET_COGS_FROM_ALGOLIA,
        payload: { hits: result.hits, length: result.nbHits },
      });
    }
  } catch {
    dispatch({
      type: RESET_COGS,
    });
  }
};

export const deleteCog = (cogs, cogId) => dispatch => {
  const result = cogs?.hits?.filter(cog => cog.id !== cogId);
  dispatch({
    type: DELETE_COG,
    payload: { ...cogs, hits: result, length: cogs.length - 1 },
  });
};

export const resetCogs = () => ({
  type: RESET_COGS,
});

export const setSearchValue = searchText => dispatch => {
  dispatch({
    type: SET_SEARCH_VALUE,
    payload: searchText,
  });
};

export const resetSearchValue = () => ({
  type: RESET_SEARCH_VALUE,
});
