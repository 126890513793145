import algoliasearch from 'algoliasearch';

const config = {
  'steamhub-im': { appId: '9GXBI9RH71', apiKey: '779a94155e5ec23c6b7db08c85d63ebb' },
  'steamdepot-prod': { appId: 'VUX4Z21FXW', apiKey: '93a84aefa92f8f96b14ded9270cada64' },
};

const searchClient = algoliasearch(
  config[process.env.REACT_APP_PROJECT_ID].appId,
  config[process.env.REACT_APP_PROJECT_ID].apiKey
);
export const cogs = searchClient.initIndex('cogs');

cogs.setSettings({
  ranking: ['desc(updatedAt)'],
});

export default searchClient;
