import { cogs } from './algolia';

export const getCogs = async ({ offset = 0, length = 25, searchText = '' }) => {
  const result = await cogs.search(searchText, {
    attributesToRetrieve: [
      'id',
      'title',
      'cover',
      'type',
      'overview',
      'subject',
      'grade',
      'authorId',
      'authorName',
    ],
    restrictSearchableAttributes: ['title'],
    offset,
    length,
  });
  return result;
};

export default getCogs;
