import { GET_COGS_FROM_ALGOLIA, RESET_COGS, DELETE_COG } from '../constants/redux';

export default (state = null, action) => {
  switch (action.type) {
    case GET_COGS_FROM_ALGOLIA:
      return action.payload;
    case DELETE_COG:
      return action.payload;
    case RESET_COGS:
      return [];
    default:
      return state;
  }
};
